
.blogStyle img {
    width: 50%;
    height: auto;
    margin-left: 20%;
}


@media only screen and (max-width: 960px) {
    .blogStyle img {
        width: 100%;
        margin-left: 0
    }
}


.blogStyle h2 {
    font-size: 1.1rem;
}

.homeHospitalCard {
    transition: all .2s;
}

.homeHospitalCard :hover {
    box-shadow: 0 16px 70px -12.125px rgba(0, 0, 0, 0.3);
}

.healthPackageCard {
    background-color: #F5F5F5;
    margin-top: 1rem;
    margin-bottom: 1rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    padding: .2rem 3rem 1rem 3rem;
    border-radius: 8px;
    white-space: pre-wrap;
    overflow-wrap: break-word;
    word-wrap: break-word
}

.downloadButton :hover {
    color: teal;
}

::-webkit-scrollbar {
    width: 0;
    /* Remove scrollbar space */
    background: transparent;
    /* Optional: just make scrollbar invisible */
}

.textHover {
    color: red;
}

.textHover :hover {
    color: teal;
}

.linkHover {
    color: white;
}

.linkHover :hover {
    color: #F64540;
}

.linkHover ::selection {
    color: red;
}

.emegcall {
    position: fixed;
    width: 40px;
    height: 40px;
    bottom: 20px;
    right: 20px;
    background-color: red;
    color: #FFF;
    border-radius: 50px;
    text-align: center;
    font-size: 15px;
    box-shadow: 2px 2px 3px #999;
    z-index: 100;
}

.emegcall1 {
    position: fixed;
    width: 40px;
    height: 40px;
    bottom: 20px;
    right: 2%;
    background-color: red;
    color: #FFF;
    border-radius: 50px;
    text-align: center;
    font-size: 15px;
    box-shadow: 2px 2px 3px #999;
    z-index: 100;
}

.myEmegcall {
    margin-top: 13px;
    color: white
}

.footer {
    width: 100%;
    position: relative;
    bottom: -3.7rem;
    margin-top: auto;
}

.footer-lessData {
    position: absolute;
    width: 100%;
    bottom: 0;
}

.wrapper {
    min-height: 100%;
    height: auto !important;
    height: 100%;
    margin: 0 auto -142px;
    /* the bottom margin is the negative value of the footer's height */
}

.banner {
    background: rgb(33, 133, 139);
    background: linear-gradient(270deg, rgba(33, 133, 139, 1) 0%, rgba(33, 117, 139, 1) 62%, rgba(0, 212, 255, 1) 100%);
    border-radius: 10px;
    color: white;
}

.grow {
    transition: all 1s ease-in-out;
}

.grow:hover {
    transform: scale(1.1);
    border: '1px solid teal'
}

table,
th,
td {
    border: 1px solid black;
    padding: 1rem;
    border-collapse: collapse;
}

.img-card:hover {
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
}

.img {
    width: 100%;
    height: 200px;
    object-fit: cover;
    margin-left: 35px;
}

.card-title {
    font-size: 20px;
    font-weight: 600;
}

.card-text {
    margin-top: 10px;
}


/* card style end */


/* carousel slider style start */

.slick-slide>div {
    margin: 0 10px;
}

.slick-list {
    margin: 0 -10px;
}

.carousel-button-group {
    position: relative;
}

.onleft {
    position: absolute;
    top: 0;
}

.fast-transition {
    position: relative;
    /* For positioning the pseudo-element */
    box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.3);
}

.fast-transition::before {
    /* Position the pseudo-element. */
    content: ' ';
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    /* Create the box shadow at expanded size. */
    box-shadow: 0 10px 50px 0 rgba(0, 0, 0, 0.5);
    /* Hidden by default. */
    opacity: 0;
    transition: opacity 500ms;
}

.fast-transition:hover::before {
    /* Show the pseudo-element on hover. */
    opacity: 1;
}

.package {
    position: relative;
    display: inline-block;
    background-color: #fff;
    border: 1px solid #DEDEDE;
    box-shadow: 0 1px 2px rgba(0, 0, 0, 0.1);
    border-radius: 10px;
    -webkit-transition: all 0.6s cubic-bezier(0.165, 0.84, 0.44, 1);
    transition: all 0.6s cubic-bezier(0.165, 0.84, 0.44, 1);
}

.package::after {
    content: "";
    border-radius: 10px;
    position: absolute;
    z-index: -1;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    box-shadow: 0 5px 15px rgba(0, 0, 0, 0.3);
    opacity: 0;
    -webkit-transition: all 0.6s cubic-bezier(0.165, 0.84, 0.44, 1);
    transition: all 0.6s cubic-bezier(0.165, 0.84, 0.44, 1);
}

.package:hover {
    -webkit-transform: translateZ(5px);
    transform: translateZ(5px);
}

.package:hover::after {
    opacity: 1;
}

.carousel-container-2 {
    position: relative;
}

.active {
    color: #02abd6;
}

.inactive {
    color: white;
}

.doctorCard {
    border-bottom-left-radius: 8px;
    border-bottom-right-radius: 8px;
}

.bottomRadiusCard {
    border-bottom-left-radius: 8px 8px;
    border-bottom-right-radius: 8px 8px;
    overflow: hidden;
}
